body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family:'SFDisplay';
	src:url('./assets/sfpro/SF\ Pro\ Display\ Regular\ font.ttf');
	/* font-weight:500; */
}
@font-face {
	font-family:'SFMedium';
	src:url('./assets/sfpro/SF-Pro-Display-Medium.otf');
}
@font-face {
	font-family:'SFBold';
	src:url('./assets/sfpro/SF-Pro-Display-Bold.otf');
}

@font-face {
	font-family:'AvenirHeavy';
	src:url('./assets/avenir/Avenir-Heavy-05.ttf');
}

@font-face {
	font-family:'AvenirMedium';
	src:url('./assets/avenir/Avenir-Medium-09.ttf');
}

html,body{
  width: 100%;
  overflow-x: hidden;
}
body{
  padding-right: 0!important; /* model open */
}