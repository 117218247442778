html, body {
  background-color: #0C0518 !important;
}

.App {
  background-color: #0C0518;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-menu .menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.header-menu .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.header-menu .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.header-menu .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header-menu .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.header-menu .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.header-menu-mobile .menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.header-menu-mobile .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.header-menu-mobile .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu-mobile .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.header-menu-mobile .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu-mobile .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header-menu-mobile .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.header-menu-mobile .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

#appbar-menu-mobile .MuiMenu-list {
  padding: 0 !important;
  width: 100%;
  height: 100%;
  padding-top: 68px !important;
}

#banner .vertical-screen {
  flex-direction: column-reverse !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-top: 85px !important;
}

.vertical-screen-title-box {
  align-items: center !important;
  padding-top: 12px !important;
}

.vertical-screen-video-box {
  width: 400px !important;
}
